import React from 'react';
import './SiteHeader.static.scss';
import { NavLink } from "react-router-dom";
import Navigation from "../Navigation/Navigation";

const SiteHeader = () => {
	return (
		<header className="page-header">
			<Navigation />
			<NavLink to="/" exact className="logo-link" aria-label="Logo representing Kudo ApS">
				<svg xmlns="http://www.w3.org/2000/svg" className="logo" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="1.414" clipRule="evenodd" viewBox="0 0 788 414">
					<text fill="transparent">Kudo</text>
					<g id="logo-5">
						<g id="logo-shape">
						<path id="logo-curve" d="M55.07 390.188C-47.784 360.186 3.105 53.296 127.894 45.637c102.797-6.309 211.552-4.512 287.064-14.515 141.749-18.778 219.474-46.761 294.405-20.254 116.531 41.223 78.102 200.458 37.457 275.629-92.983 171.964-609.126 127.792-691.75 103.691z"/>
						</g>
						<g id="logo-letters" className="logo__letters" fill="#fff" fillRule="nonzero" stroke="#fff">
						<path id="letter-k" strokeWidth="11" d="M352.537 107.435c-12.255.797-33.389 11.017-63.401 30.659-30.013 19.642-57.244 40.353-81.694 62.134 3.975 20.893 11.935 43.264 23.949 66.969 26.375 52.04 46.403 56.517 62.35 62.896-51.897 13.162-71.751-22.792-79.376-34.699-7.625-11.907-14.09-25.283-19.395-40.129-5.306-14.846-8.234-26.674-8.786-35.483-12.129 11.749-22.524 23.289-31.185 34.621-3.554 4.653-8.119 13.748-13.696 27.282-5.577 13.535-8.658 22.965-9.244 28.291-.586 5.326 1.011 11.244 1.046 12.948l-37.052-4.591c8.749-24.22 22.084-53.313 42.006-95.278s36.98-72.158 51.174-90.579c.021-.194 1.904-2.624 5.65-7.29-16.319 1.446-33.202 5.86-50.649 13.244-17.447 7.384-27.99 15.521-31.63 24.411-.256.593-.671.909-1.245.946-1.532.1-2.159-1.109-1.882-3.627.042-.387 1.096-3.052 3.161-7.993 2.064-4.942 5.539-10.167 10.422-15.677 4.884-5.509 10.337-9.71 16.358-12.601 9.809-4.483 21.702-7.18 35.68-8.089l64.912-4.221c-10.383 7.983-23.471 24.986-39.262 51.011-15.792 26.024-28.955 49.763-39.49 71.215 9.087-11.744 20.939-24.869 35.558-39.376 14.619-14.507 30.132-28.495 46.538-41.965 16.405-13.469 33.061-24.888 49.966-34.256 16.905-9.368 31.341-14.441 43.309-15.219 11.967-.778 17.802.189 17.504 2.9-.107.969-.639 1.484-1.596 1.546z"/>
						<path id="letter-u" strokeWidth="11" d="M404.055 199.216l14.443-.636c3.016-.196 4.937-.422 5.763-.678-6.054 4.23-15.629 18.229-28.724 41.997-13.096 23.768-20.203 40.735-21.322 50.903-.671 6.101 1.004 9.021 5.025 8.759 2.614-.17 6.373-2.13 11.277-5.881 4.904-3.752 9.194-7.816 12.869-12.194 3.676-4.379 7.709-9.739 12.1-16.082 4.391-6.342 8.19-11.839 11.396-16.489 3.207-4.651 5.011-6.989 5.413-7.015 1.207-.078 1.597 1.814 1.172 5.678-.425 3.864-1.934 7.596-4.526 11.197-29.563 43.717-52.688 66.118-69.376 67.203-11.259.732-15.96-7.341-14.102-24.22a100.098 100.098 0 0 1 2.884-15.331c-21.875 28.276-41.357 42.97-58.447 44.081-5.026.327-8.863-1.544-11.509-5.612-2.647-4.068-3.511-10.27-2.594-18.608.918-8.338 5.349-22.305 13.294-41.902 7.946-19.596 16.823-35.872 26.632-48.826 2.347-3.181 4.715-5.203 7.105-6.065 2.391-.862 6.803-1.502 13.237-1.92l14.476-.942c-5.788 7.241-12.245 16.949-19.374 29.123-16.335 28.723-25.219 49.592-26.651 62.607-.851 7.728 1.489 11.412 7.018 11.052 5.529-.359 12.706-6.176 21.532-17.451 8.827-11.275 18.558-25.789 29.195-43.541 10.637-17.753 16.782-27.794 18.435-30.122 8.737-12.279 16.524-18.64 23.359-19.085z"/>
						<path id="letter-d" strokeWidth="11" d="M631.311 84.137c-8.133 6.067-19.708 22.337-34.725 48.811-15.016 26.474-28.762 54.25-41.237 83.328-12.476 29.078-19.417 50.008-20.823 62.791-.819 7.437.709 11.03 4.583 10.778 3.873-.252 7.997-3.123 14.37-8.614 6.373-5.491 10.351-11.327 34.702-66.076 5.065-11.387-.63 40.67-22.153 67.683-7.073 8.767-14.336 17.034-23.787 24.802-9.452 7.768-17.709 11.881-24.773 12.34-12.077.786-17.105-8.002-15.085-26.362 1.484-13.48 6.693-31.01 15.628-52.589-12.809 21.831-26.792 40.969-41.949 57.415-15.158 16.446-29.458 25.106-42.902 25.98-15.267.993-21.724-9.202-19.371-30.584 1.79-16.268 7.081-32.995 15.872-50.181s20.327-32.069 34.606-44.65c14.28-12.582 28.996-19.365 44.149-20.35 15.153-.986 25.015 3.45 29.586 13.305 7.787-13.197 15.962-28.381 24.525-45.552 8.564-17.171 14.203-28.325 16.917-33.463a2524.3 2524.3 0 0 1 5.994-11.292 41.162 41.162 0 0 1 4.659-6.879c2.865-3.417 6.108-5.647 9.729-6.69 3.621-1.043 9.369-1.879 17.243-2.506 7.874-.627 12.621-1.109 14.242-1.445zm-95.478 112.122c-2.274-4.006-6.601-5.801-12.981-5.386-10.254.666-21.147 6.624-32.678 17.873-11.531 11.249-21.191 23.991-28.979 38.227-7.788 14.236-12.257 26.584-13.408 37.042-.793 7.205 1.545 10.629 7.014 10.274 18.457-1.2 45.467-33.877 81.032-98.03z"/>
						<path id="letter-o" strokeLinejoin="miter" strokeMiterlimit="3" strokeWidth="18" d="M684.015 224.067c-6.99 18.574-17.386 34.93-31.189 49.069-13.802 14.139-28.71 21.729-44.722 22.77-11.03.717-18.86-2.765-23.491-10.447-4.63-7.682-6.243-17.904-4.839-30.666 1.826-16.591 7.848-33.328 18.067-50.212 11.07-18.206 23.93-31.269 38.58-39.191 8.13-4.33 16.406-6.769 24.827-7.316 11.268-.733 19.287 2.642 24.057 10.124 4.77 7.483 6.467 17.478 5.091 29.985-.913 8.295-3.04 16.923-6.381 25.884zm-65.826 62.06c8.065-.525 16.313-5.654 24.744-15.389 8.431-9.735 15.317-21.206 20.656-34.415 5.34-13.208 8.592-25.108 9.758-35.701 1.165-10.593.149-19.016-3.048-25.271-3.198-6.254-8.77-9.123-16.717-8.606-7.947.517-16.052 5.415-24.315 14.696-8.263 9.28-15.106 20.369-20.529 33.266-5.423 12.896-8.767 25.088-10.031 36.574-1.263 11.486-.349 20.296 2.744 26.431 3.094 6.134 8.673 8.939 16.738 8.415z"/>
						</g>
					</g>
				</svg>
			</NavLink>
		</header>
	)
}

export default SiteHeader;