import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import WebFont from 'webfontloader';

import SiteHeader from './components/SiteHeader/SiteHeader';
import './styles/index.scss';

import {
	CSSTransition, 
	TransitionGroup, 
} from 'react-transition-group';

//import Home from "./pages/Home/Home";
//import About from "./pages/About/About";
//import Gdpr from "./pages/Misc/Gdpr";
//import Contact from "./pages/Contact/Contact";
//import Error from "./pages/Error/Error";

const Home = lazy(() => import('./pages/Home/Home'));
const About = lazy(() => import('./pages/About/About'));
const Gdpr = lazy(() => import('./pages/Misc/Gdpr'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const Error = lazy(() => import('./pages/Error/Error'));



WebFont.load({
  google: {
    families: ['Quicksand:400,700', 'Playball&display=fallback']
    
  }
});

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <React.Fragment>
          <SiteHeader />
          <Route render={({location}) => (
            <TransitionGroup component={'main'} role="main" className="main transition-group">
              <CSSTransition 
                key={location.key}
                timeout={450}
                classNames="transition"
              >
                <section className="transition-item">
                <Suspense fallback={<div className="loading-animation"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}>
                  <Switch location={location}>
                    <Route path="/" component={Home} exact />
                    <Route path="/about" component={About} exact />
                    <Route path="/gdpr" component={Gdpr} />
                    <Route path="/contact" component={Contact} />
                    <Route component={Error} />
                  </Switch>
                </Suspense>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )} />
        </React.Fragment>
      </BrowserRouter>
      
    </React.Fragment>
  );
}

export default App;
