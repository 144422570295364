import React from 'react';
import { NavLink } from "react-router-dom";
import './Navigation.static.scss';

const Navigation = () => {
	return (
		<div className='navigation'>
			<NavLink className='navigation__item' to="/about" >About</NavLink>
			<NavLink className='navigation__item' to="/contact" >Contact</NavLink>
		</div>
	)
}

export default Navigation;